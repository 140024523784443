import "./i18n/config";
import { Route, Routes } from "react-router-dom";
import AnonymLayout from "./layouts/AnonymLayout";
import Home from "./pages/Home";
import UseCases from "./pages/UseCases";
import Customers from "./pages/Customers";
import Contact from "./pages/Contact";
import Features from "./pages/Features";
import NotFound from "./pages/NotFound";
import LoginPage from "./pages/LoginPage";
import ProtectedLayout from "./layouts/ProtectedLayout";
import ClientsPage from "./pages/ClientsPage";
import HomePage from "./pages/protected/HomePage";
import NotFound2 from "./pages/protected/NotFound2";
import ModulesPage from "./pages/ModulesPage";
import { OrcContextProvider } from "./context/OrcContext";
import SamplePage1 from "./pages/protected/SamplePage1";
// const OrganizationPage = React.lazy(
//   () => import("./pages/protected/OrganizationPage")
// );
// const AnalyticsPage = React.lazy(
//   () => import("./pages/protected/AnalyticsPage")
// );
// const SamplePage2 = React.lazy(() => import("./pages/protected/SamplePage2"));
// const DataDictionary = React.lazy(
//   () => import("./pages/protected/Development/DataDictionary/DataDictionary")
// );
import DataDictionary from "./pages/protected/Development/DataDictionary/DataDictionary";
import TilesPage from "./pages/protected/TilesPage";
import CreateDomainDetail from "./pages/protected/Development/DomainDetail/CreateDomainDetail";
import DisplayDomainDetail from "./pages/protected/Development/DomainDetail/DisplayDomainDetail";
import ChangeDomainDetail from "./pages/protected/Development/DomainDetail/ChangeDomainDetail";
import CreateDataElementDetail from "./pages/protected/Development/DataElementDetail/CreateDataElementDetail";
import DisplayDataElementDetail from "./pages/protected/Development/DataElementDetail/DisplayDataElementDetail";
import ChangeDataElementDetail from "./pages/protected/Development/DataElementDetail/ChangeDataElementDetail";
import CreateTableDetail from "./pages/protected/Development/TableDetail/CreateTableDetail";
import Programming from "./pages/protected/Development/Programming/Programming";
import CreateProgramDetail from "./pages/protected/Development/Programming/CreateProgramDetail";
import ChangeProgramDetail from "./pages/protected/Development/Programming/ChangeProgramDetail";
import DynamicPage from "./pages/protected/DynamicPage/DynamicPage";
import ChangeTableDetail from "./pages/protected/Development/TableDetail/ChangeTableDetail";
import DisplayTableDetail from "./pages/protected/Development/TableDetail/DisplayTableDetail";
import DeleteDomainDetail from "./pages/protected/Development/DomainDetail/DeleteDomainDetail";
import DeleteDataElementDetail from "./pages/protected/Development/DataElementDetail/DeleteDataElementDetail";
import DeleteTableDetail from "./pages/protected/Development/TableDetail/DeleteTableDetail";
import CreateStructureDetail from "./pages/protected/Development/StructureDetail/CreateStructureDetail";
import ChangeStructureDetail from "./pages/protected/Development/StructureDetail/ChangeStructureDetail";
import DisplayStructureDetail from "./pages/protected/Development/StructureDetail/DisplayStructureDetail";
import DeleteStructureDetail from "./pages/protected/Development/StructureDetail/DeleteStructureDetail";
import CreateViewDetail from "./pages/protected/Development/ViewDetail/CreateViewDetail";
import ChangeViewDetail from "./pages/protected/Development/ViewDetail/ChangeViewDetail";
import DisplayViewDetail from "./pages/protected/Development/ViewDetail/DisplayViewDetail";
import DeleteViewDetail from "./pages/protected/Development/ViewDetail/DeleteViewDetail";
import React, { Suspense } from "react";
import UIPage from "./pages/protected/UIPage/UIPage";
import UserManagement from "./pages/protected/Identity/UserManagement/UserManagement";
import CreateUserDetail from "./pages/protected/Identity/UserDetail/CreateUserDetail";
import ChangeUserDetail from "./pages/protected/Identity/UserDetail/ChangeUserDetail";
import DeleteUserDetail from "./pages/protected/Identity/UserDetail/DeleteUserDetail";
import DisplayUserDetail from "./pages/protected/Identity/UserDetail/DisplayUserDetail";
import CreatePageDetail from "./pages/protected/UIPage/CreatePageDetail";
import ChangePageDetail from "./pages/protected/UIPage/ChangePageDetail";
import EmptyLayout from "./layouts/EmptyLayout";
import ClientLoginRedirectPage from "./pages/ClientLoginRedirectPage";
import ProductPage from "./pages/ProductPage";
import TrainingPage from "./pages/TrainingPage";
import ThankPage from "./pages/ThankPage";
import ClientRegisterPage from "./pages/ClientRegisterPage";
import ChangePassword from "./pages/protected/Identity/UserDetail/ChangePassword";
import HRDEVSHOPForm from "./components/HRDEVSHOPForm";
import ContactHRDevShop from "./pages/ContactHRDevShop";
import DisplayPageDetail from "./pages/protected/UIPage/DisplayPageDetail";
import PrivacyPage from "./pages/PrivacyPage";
import DeletePageDetail from "./pages/protected/UIPage/DeletePageDetail";
import DeleteProgramDetail from "./pages/protected/Development/Programming/DeleteProgramDetail";
import DisplayProgramDetail from "./pages/protected/Development/Programming/DisplayProgramDetail";

function App() {
  // const { t } = useTranslation();

  return (

    <OrcContextProvider>
      <Routes>
        <Route element={<AnonymLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/usecases" element={<UseCases />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/hrteched" element={<TrainingPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/orchestrabuild" element={<ContactHRDevShop />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/thankyou" element={<ThankPage />} />
          <Route path="/features" element={<Features />} />
          <Route path="/company/new" element={<ClientRegisterPage />} />
          <Route path="/company/go" element={<ClientLoginRedirectPage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/modules" element={<ModulesPage />} />
        </Route>
        <Route element={<EmptyLayout />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route path="/apps" element={<ProtectedLayout />}>
          <Route path="/apps/home" element={<HomePage />} />
          <Route path="/apps/ddic" element={<DataDictionary />} />
          <Route path="/apps/ddic" element={<DataDictionary />} />
          <Route path="/apps/se38" element={<Programming />} />
          <Route
            path="/apps/program/create/:progname"
            element={<CreateProgramDetail />}
          />
          <Route
            path="/apps/program/change/:progname"
            element={<ChangeProgramDetail />}
          />
          <Route
            path="/apps/program/delete/:progname"
            element={<DeleteProgramDetail />}
          />
          <Route
            path="/apps/program/display/:progname"
            element={<DisplayProgramDetail />}
          />
          <Route
            path="/apps/domain/create/:domname"
            element={<CreateDomainDetail />}
          />
          <Route
            path="/apps/domain/display/:domname"
            element={<DisplayDomainDetail />}
          />
          <Route
            path="/apps/domain/change/:domname"
            element={<ChangeDomainDetail />}
          />
          <Route
            path="/apps/domain/delete/:domname"
            element={<DeleteDomainDetail />}
          />
          <Route
            path="/apps/dataelement/create/:rollname"
            element={<CreateDataElementDetail />}
          />
          <Route
            path="/apps/dataelement/display/:rollname"
            element={<DisplayDataElementDetail />}
          />
          <Route
            path="/apps/dataelement/delete/:rollname"
            element={<DeleteDataElementDetail />}
          />
          <Route
            path="/apps/dataelement/change/:rollname"
            element={<ChangeDataElementDetail />}
          />
          <Route
            path="/apps/table/create/:tabname"
            element={<CreateTableDetail />}
          />
          <Route
            path="/apps/table/change/:tabname"
            element={<ChangeTableDetail />}
          />
          <Route
            path="/apps/table/display/:tabname"
            element={<DisplayTableDetail />}
          />
          <Route
            path="/apps/table/delete/:tabname"
            element={<DeleteTableDetail />}
          />
          {/* Structure Screens - Start */}
          <Route
            path="/apps/structure/create/:tabname"
            element={<CreateStructureDetail />}
          />
          <Route
            path="/apps/structure/change/:tabname"
            element={<ChangeStructureDetail />}
          />
          <Route
            path="/apps/structure/display/:tabname"
            element={<DisplayStructureDetail />}
          />
          <Route
            path="/apps/structure/delete/:tabname"
            element={<DeleteStructureDetail />}
          />
          {/* Structure Screens - Ends */}
          {/* View Screens - Start */}
          <Route
            path="/apps/view/create/:tabname"
            element={<CreateViewDetail />}
          />
          <Route
            path="/apps/view/change/:tabname"
            element={<ChangeViewDetail />}
          />
          <Route
            path="/apps/view/display/:tabname"
            element={<DisplayViewDetail />}
          />
          <Route
            path="/apps/view/delete/:tabname"
            element={<DeleteViewDetail />}
          />
          {/* View Screens - Ends */}
          {/* Page Editor Start */}
          <Route
            path="/apps/pages"
            element={<UIPage />}
          />
          <Route
            path="/apps/pages/create/:pagename"
            element={<CreatePageDetail />}
          />
          <Route
            path="/apps/pages/change/:pagename"
            element={<ChangePageDetail />}
          />
          <Route
            path="/apps/pages/display/:pagename"
            element={<DisplayPageDetail />}
          />
          <Route
            path="/apps/pages/delete/:pagename"
            element={<DeletePageDetail />}
          />
          {/* Page Editor Ends */}
          {/* User Editor Start */}
          <Route
            path="/apps/users"
            element={<UserManagement />}
          />
          <Route
            path="/apps/users/create/:username"
            element={<CreateUserDetail />}
          />
          <Route
            path="/apps/users/change/:username"
            element={<ChangeUserDetail />}
          />
          <Route
            path="/apps/users/delete/:username"
            element={<DeleteUserDetail />}
          />
          <Route
            path="/apps/users/display/:username"
            element={<DisplayUserDetail />}
          />
          <Route
            path="/apps/users/passchange/:username"
            element={<ChangePassword />}
          />
           <Route path="/apps/execute/:appname" element={<DynamicPage />} />
           <Route path="/apps/run/:appname" element={<DynamicPage />} />
          {/* User Editor Ends */}
          <Route path="/apps/exec/:part1" element={<DynamicPage />} />
            {/* /apps/exec/hr/ess */}
          <Route
            path="/apps/exec/:part1/:part2"
            element={<DynamicPage />}
          />
             {/* /apps/exec/hr/ess */}
          <Route
            path="/apps/exec/:part1/:part2/:part3"
            element={<DynamicPage />}
          />
          <Route
            path="/apps/exec/:part1/:part2/:part3/:part4"
            element={<DynamicPage />}
          />
                    <Route
            path="/apps/exec/:part1/:part2/:part3/:part4/:part5"
            element={<DynamicPage />}
          />
                              <Route
            path="/apps/exec/:part1/:part2/:part3/:part4/:part5/:part6"
            element={<DynamicPage />}
          />
                                        <Route
            path="/apps/exec/:part1/:part2/:part3/:part4/:part5/:part6/:part7"
            element={<DynamicPage />}
          />
          <Route path="/apps/menu/ess-comp" element={<NotFound2 />} />  
          <Route path="/apps/sample1" element={<SamplePage1 />} />
          {/* <Route path="/apps/sample2" element={<SamplePage2 />} />
          <Route path="/apps/organization" element={<OrganizationPage />} />
          <Route path="/apps/analytics" element={<AnalyticsPage />} /> */}
          <Route path="/apps/tiles/:menuid" element={<TilesPage />} />
          <Route path="/apps/*" element={<NotFound2 />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </OrcContextProvider>

  );
}

export default App;
