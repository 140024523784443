import { TrendingUp } from "lucide-react"
import { Pie, PieChart } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shadcn/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/shadcn/ui/chart"
import { useEffect, useState } from "react"
import useHttpClient from "@/hook/useHttpClient"
import IResponseHelper from "@/models/response/IResponseHelper"

export const description = "A donut chart"

const chartData = [
  { browser: "chrome", visitors: 275, fill: "#7C0902" },
  { browser: "safari", visitors: 200, fill: "#9F8170" },
  { browser: "firefox", visitors: 187, fill: "#592720" },
  { browser: "edge", visitors: 173, fill: "#893F45" },
  { browser: "other", visitors: 90, fill: "#996515" },
]

const chartConfig = {
  DataValue: {
    label: "Visitors",
  },
  chrome: {
    label: "Chrome",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Safari",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Firefox",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Edge",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

export interface IOrcPieChart {
  Progname: string;
}
enum Trend {
  Up = 0,
  Down = 1,
}

class KPIPieChartModel {
  Title: string = "";
  SubTitle: string = "";
  ChartData: KPIPieChartModelDataItem[] = [];
  Units: string = "";
  Footer: string = "";
  ShowTrend: boolean = true;
  InnerRadius: number = 60;
  Trend: Trend = Trend.Up;
  TrendWord: string = "";
}

class KPIPieChartModelDataItem {
  DataKey: string = "";
  DataValue: number = 0;
  fill: string = "";

}

export function OrcPieChart(props: IOrcPieChart) {

  const [loaded, setLoaded] = useState(false);
  const { isLoading, request } = useHttpClient();

  let initialKPI: KPIPieChartModel = new KPIPieChartModel();

  const [KPIData, setKPIData ] =  useState<KPIPieChartModel>(initialKPI);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await request<IResponseHelper<KPIPieChartModel>>(`/api/v1/program?ProgName=` + props.Progname, "GET");
    if(resp==null) return;
    if (resp.data) {
      setKPIData(resp.data);
      console.log(resp.data);
      setLoaded(true);
      //
    } else {
      setLoaded(true);

    }
    debugger;
  };
  
  return (loaded ? <Card className="flex flex-col min-w-80">
      <CardHeader className="items-center pb-0 ">
      <CardTitle>{KPIData.Title}</CardTitle>
      <CardDescription>{KPIData.SubTitle}</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square"
        >
          <PieChart className="">
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
             data={KPIData.ChartData}
              //data={chartData}
              dataKey="DataValue"
              nameKey="DataKey"
              // label
              // labelLine={false}
              innerRadius={KPIData.InnerRadius}
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
      {/* <CardFooter className="flex-col gap-2 text-sm">
        <div className="flex items-center gap-2 font-medium leading-none">
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing total visitors for the last 6 months
        </div>
      </CardFooter> */}
     <CardFooter className="flex-col gap-2 text-sm">
        {/* <div className="flex items-center gap-2 font-medium leading-none">
          Trending up by 5.2% this month <div className="h-4 w-4" />
        </div> */}
        <div className="leading-none text-muted-foreground">
        {/* {KPIData.Footer} */}
         {KPIData.Footer} 
        </div>
      </CardFooter>
    </Card> : <div>Loading...</div>
  )
}
