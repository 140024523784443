import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Checkbox } from "@/shadcn/ui/checkbox";


export interface IOrcCheckBox {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Data?: any;
  ParentFieldName: string;
  Disabled? : boolean;
  Required? : boolean;
  HasError?: boolean;
  Caption?: string;
  ScreenName?: string;
  RollName?: string;
  DomName?: string;
  DataType?: string;
  OnChecked?: (_value: boolean) => void;
  HelpRequested?: (e:any) => void;
}

export default function OrcCheckBox(props: IOrcCheckBox) {
  //
  

  return (
    <div className="flex flex-col gap-1" >
       {props.Caption ? <Label htmlFor={props.FieldName} className={clsx("font-semibold", props.CaptionClassName ? props.CaptionClassName : "")}>{props.Caption + (props.Required ? " *" : "")}</Label>: null} 
       <div>
      <Checkbox
      className="bg-white"
        checked={props.Data}
        disabled={props.Disabled}
        onCheckedChange={(checked :boolean) =>
          props.OnChecked && props.OnChecked(checked)
        }
      />


       </div>

       
    </div>
  );
}
