import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";
import Item from "@/models/screen/Item";
import {  Constants } from "@/models/Consts";

export interface IOrcSelect {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Data?: string;
  ParentFieldName: string;
  Disabled? : boolean;
  Required? : boolean;
  HasError?: boolean;
  Caption?: string;
  ScreenName?: string;
  RollName?: string;
  DomName?: string;
  DataType?: string;
  Items? : Item[];
  OnSelect?: (_value: string) => void;
  HelpRequested?: (e:any) => void;
}

export default function OrcSelect(props: IOrcSelect) {
  //
  const items = props.Items || [];

  return (
    <div className="flex flex-col gap-1" >
       {props.Caption ? <Label htmlFor={props.FieldName} className={clsx("font-semibold", props.CaptionClassName ? props.CaptionClassName : "")}>{props.Caption + (props.Required ? " *" : "")}</Label>: null} 
       <div>
       <Select   
          value={props.Data}
          onValueChange={(e) => props.OnSelect ? props.OnSelect(e) : null}
          disabled={props.Disabled}>
          
          <SelectTrigger 
              onKeyDown={(e) => props.HelpRequested ? (e.key == "F1" ? props.HelpRequested(e) : null) : null}
              className={clsx("rounded-md p-2 text-left bg-white",
                      props.ClassName,
                      props.HasError ? "border-red-200 bg-red-50 border-2" : "border-gray-300 border",
                      props.Disabled ? Constants.InActive : ""
                  )
                }
              disabled={props.Disabled}>
              <SelectValue placeholder=".." className="bg-white" />
          </SelectTrigger>
          <SelectContent>
              <SelectGroup>
                {items.map((item) => (
                  <SelectItem key={item.Value} value={item.Value}>{item.Text}</SelectItem>
                ))}
              </SelectGroup>
          </SelectContent>
      </Select>
       </div>

       
    </div>
  );
}
