import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Constants } from "@/models/Consts";

export interface IOrcTextArea {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Data: string;
  ParentFieldName: string;
  Disabled? : boolean;
  Required? : boolean;
  HasError?: boolean;
  Caption?: string;
  PlaceHolder?: string;
  ScreenName?: string;
  RollName?: string;
  DomName?: string;
  DataType?: string;
  Length?: number;
  Rows?: number;
  OnChange?: (_value: string) => void;
  HelpRequested?: (e:any) => void;
}

export default function OrcTextArea(props: IOrcTextArea) {
  //


  return (
    <div className={clsx("flex flex-col gap-1")} >
       {props.Caption ? <Label htmlFor={props.FieldName} className={clsx("font-semibold", props.CaptionClassName ? props.CaptionClassName : "")}>{props.Caption + (props.Required ? " *" : "")}</Label>: null} 
       <div>
       <textarea  key={props.FieldName} maxLength={props.Length} 
            onKeyDown={(e) => props.HelpRequested ? (e.key == "F1" ? props.HelpRequested(e) : null) : null} 
            onChange={(e) => props.OnChange ? props.OnChange(e.target.value) : null} 
            className={clsx("rounded-md p-2 text-left w-28",
              props.ClassName,
            props.HasError ? "border-red-200 bg-red-50 border-2" : "border-gray-300 border",
            props.Disabled ? Constants.InActive : ""
          )
        }
          disabled={props.Disabled}
          placeholder={props.PlaceHolder} 
          value={props.Data}
          rows={props.Rows ? props.Rows : 5}
            />
       </div>

       
    </div>
  );
}
