import { Icon } from "@iconify/react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

export interface ITile {
  FieldName: string;
  Title: string;
  SubTitle?: string;
  IconName?: string;
  ClassName?: string;
  IconClassName?: string;
  TextClassName?: string;
  FigureClassName?: string;
  NavigateTo?: string;
  Figure?: string;
}

function Tile(props: ITile) {
 // console.log(JSON.stringify(props));

  const navigate = useNavigate();

  const GotoPage = (navTo: string) => {
    debugger;
    if(navTo.toLowerCase().startsWith("http"))
    {
      window.open(navTo, "_blank"); 
    }
    else if(navTo.toLowerCase().startsWith("orgchart.html"))
    {
      window.open("/orgchart.html", "_blank", "width=900,height=500, menubar=no,toolbar=no,location=no,status=no");
    }
    else
      navigate(navTo);
  };

  return (
    <div
      key={props.FieldName}
      className={clsx(
        "relative w-36 h-36 md:w-44 md:h-44 p-4 text-foreground bg-opacity-90 hover:bg-opacity-35 hover:cursor-pointer transition duration-200 hover:scale-110 rounded-sm",
        props.ClassName
      )}
      onClick={() => GotoPage(props.NavigateTo ? props.NavigateTo : "")}
      // style={{ backgroundColor: "red" }}
    >
      {/* <p className="font-normal text-base">{props.Title}</p> */}
      <p className={clsx("font-normal md:text-lg", props.TextClassName)}>{props.Title}</p>
      <p className={clsx("hidden md:flex font-light text-xs", props.TextClassName)}>
        {props.SubTitle}
      </p>
      <Icon
        icon={props.IconName ? props.IconName : ""}
        className={clsx(
          "absolute w-10 h-10 bottom-3 left-4",
          props.IconClassName
        )}
        width="18"
        height="18"
      />
      <div className={clsx("absolute right-2 bottom-3 text-2xl md:text-6xl text-black", props.FigureClassName)}>
        {props.Figure}
      </div>
    </div>
  );
}

export default Tile;
