import { TrendingDown, TrendingUp } from "lucide-react"
import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shadcn/ui/card"
import { ChartConfig, ChartContainer } from "@/shadcn/ui/chart"
import { useEffect, useState } from "react"
import useHttpClient from "@/hook/useHttpClient";
import IResponseHelper from "@/models/response/IResponseHelper";
import { Icon } from "@iconify/react"
import clsx from "clsx"
export const description = "A radial chart with a custom shape"

const chartData = [
  { browser: "safari", visitors: 1260, fill: "var(--color-safari)" },
]

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  safari: {
    label: "Safari",
    color: "#FFBF00",
  },
} satisfies ChartConfig

export interface IOrcRadialChart {
  Progname: string;
}

enum Trend {
  Up = 0,
  Down = 1,
}

class KPIRadialChartModel {
  Title: string = "";
  SubTitle: string = "";
  EndAngle: number = 100;
  Value: string = "";
  Units: string = "";
  Footer: string = "";
  ShowTrend: boolean = true;
  Trend: Trend = Trend.Up;
  TrendWord: string = "";
}

export function OrcRadialChart(props: IOrcRadialChart) {

  const [loaded, setLoaded] = useState(false);
  const { isLoading, request } = useHttpClient();

  let initialKPI: KPIRadialChartModel = new KPIRadialChartModel();

  const [KPIData, setKPIData ] =  useState<KPIRadialChartModel>(initialKPI);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await request<IResponseHelper<KPIRadialChartModel>>(`/api/v1/program?ProgName=` + props.Progname, "GET");
    if(resp==null) return;
    if (resp.data) {
      setKPIData(resp.data);
      //
    } else {
      setLoaded(true);

    }
    debugger;
  };

  const Trending = (t : Trend) => {
    if(t == Trend.Down){
      return <TrendingDown className="h-4 w-4" />
    }
    else {
      return <TrendingUp className="h-4 w-4" />
    }
  }


  return (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
      <CardTitle>{KPIData.Title}</CardTitle>
      <CardDescription>{KPIData.SubTitle}</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <RadialBarChart
            data={chartData}
            endAngle={KPIData.EndAngle}
            innerRadius={80}
            outerRadius={140}
          >
            <PolarGrid
              gridType="circle"
              radialLines={false}
              stroke="none"
              className="first:fill-muted last:fill-background"
              polarRadius={[86, 74]}
            />
            <RadialBar dataKey="visitors" background />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-5xl font-bold"
                        >
                          {/* {chartData[0].visitors.toLocaleString()} */}
                          {KPIData.Value}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          {KPIData.Units}
                        </tspan>
                      </text>
                    )
                  }
                }}
              />
            </PolarRadiusAxis>
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        {/* {KPIData.ShowTrend && <div className="flex items-center gap-2 font-medium leading-none">
          {KPIData.TrendWord} {KPIData.Trend == Trend.Down ? <TrendingDown className="h-4 w-4" /> : <TrendingUp className="h-4 w-4" />}
        </div>} */}
        {KPIData.ShowTrend && <div className="flex items-center gap-2 font-medium leading-none">
          {KPIData.TrendWord  }    
          
          {/* <Icon icon="ep:refresh-right" width="19" height="19" /> */}
        </div>}
        {/* <TrendingUp className="h-4 w-4" /> */}
        {/* {KPIData.ShowTrend && KPIData.Trend == Trend.Up && <div className="flex items-center gap-2 font-medium leading-none">
          {KPIData.TrendWord} 
          <TrendingUp className="h-4 w-4" />
        </div>} */}
        <div className="leading-none text-muted-foreground">
        {KPIData.Footer}
        </div>
      </CardFooter>
    </Card>
  )
}
